<template>
  <div class="home" :style="{ minHeight: wH + 'px' }">
    <div class="home-bg">
      <div class="bg-image">
        <div class="bg-title">
          <img
            src="//download-cos.yofish.com/yy-gongjushiyebu/2022081915212287-blind-title.png"
            alt="">
        </div>
        <div class="marguee-row">
          <div class="marquee_box">
            <ul class="marquee_list" :class="{marquee_top: animate}">
              <li v-for="(item, index) in marqueeList" :key="index">
                <span>{{item.name}}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="blind-box-row">
          <div class="blind-circle">
            <img
            src="//download-cos.yofish.com/blind-gongjushiyebu/20220728170828646-blind-circle.png"
            alt="">
          </div>
          <!-- <div class="blind-box"></div> -->
          <div class="blind-box">
            <div class="bg1">
              <!-- <span>1</span> -->
              <img src="./image/left1.png" alt="">
            </div>
            <div class="bg2">
              <!-- <span>2</span> -->
              <img src="./image/top1.png" alt="">
            </div>
            <div class="bg3">
              <!-- <span>3</span> -->
              <img src="./image/right1.png" alt="">
            </div>
            <div class="bg4">
              <!-- <span>4</span> -->
              <img src="./image/right2.png" alt="">
            </div>
            <div class="bg5">
              <!-- <span>5</span> -->
              <img src="./image/top2.png" alt="">
            </div>
            <div class="bg6">
              <!-- <span>6</span> -->
              <img src="./image/left2.png" alt="">
            </div>
          </div>
          <div class="bubble">
            <img
            src="//download-cos.yofish.com/yy-gongjushiyebu/20220822085654913-jd_bubble.png"
            alt="">
          </div>
          <div class="bubble">
            <img
            src="//download-cos.yofish.com/yy-gongjushiyebu/20220822085803320-kq_bubble.png"
            alt="">
          </div>
        </div>
        <p class="blind-txt-title">剩余拆盲盒次数：<span class="green">{{ openCount }}次</span></p>
        <div class="blind-btn" v-if="!openCount" @click="paymentAction">
        </div>
        <div class="blind-btn open-blind-btn" v-else @click="disBlindBoxAction"></div>
        <div class="blind-prize">
          <div class="center-line">
            <img
              src="//download-cos.yofish.com/bline-gongjushiyebu/20220728151904687-center-line.png"
              alt="">
          </div>
          <div class="prize-title-desc">
            <span @click="ruleVisible = true">活动规则</span>
            <i class="line"></i>
            <span @click="jumpAction">所有奖品</span>
          </div>
          <div class="prize-title"><span>中奖率100%拆盒必得其一</span></div>
          <div class="prize-row" ref="prizeRow" id="prizeRow">
            <ul class="prize-list" ref="prizeEle">
              <!-- :style="{ transform: 'translateX(-'+ scrollLeft +'px)' }"> -->
              <li v-for="(item, index) in prizeList" :key="index">
                <div class="prize-frame">
                  <div class="sign" v-if="item.tag">{{ item.tag }}</div>
                  <div class="prize-image">
                    <img
                      :src="item.imageUrl"
                        alt="">
                  </div>
                  <p class="prize-desc" v-if="item.desc">{{ item.desc }}</p>
                </div>
                <p class="prize-name">{{ item.name }}</p>
              </li>
            </ul>
          </div>
        </div>
        <div class="prize-task">
          <div class="prize-title prize-title2">
            <span>邀请新用户助力</span>
          </div>
          <div class="prize-task-frame">
            <div class="task-content">
              <div class="desc-content">
                <p >邀请1名新用户下载注册</p>
                <p class="bold">(获得1次抽盲盒机会)</p>
              </div>
              <div class="button"
                @click="inviteAction">去邀请</div>
            </div>
          </div>
          <p class="task-desc">注：一天只能完成一次任务，每个用户可完成三次任务。</p>
        </div>
      </div>
    </div>
    <y-dialog :visible.sync="dialogData.visible" :appendToBody="true"
      class="activity-dialog-main"
      :is-out-click="dialogData.type === 'wechart'">
      <div class="activity-dialog">
        <h1 class="title">{{ dialogData.title }}</h1>
        <p class="content">{{ dialogData.content }}</p>
      </div>
      <div class="dialog-btns" slot="footer">
        <div class="btn success copyBtn" @click="okAction('wechart')"
          v-if="dialogData.type === 'wechart'"  :data-clipboard-text="copyText">去微信粘贴给好友</div>
        <div class="btn primary" @click="okAction"
          v-if="dialogData.type === 'activityTime' || dialogData.type === 'close'">我知道了</div>
        <div class="btn primary" v-if="dialogData.type === 'endTime'"
        @click="jumpAction">立即兑换</div>
      </div>
    </y-dialog>
    <rule-modal :visible="ruleVisible"></rule-modal>
    <prize-model :prize-data="prizeData" :visible="prizeVisible"
      dialog-type="prize" @prize-ok="prizeOkAction"
      @close="closeDialog"></prize-model>
      <div class="loading">
        <y-loading v-show="loadingVisible" />
      </div>
  </div>
</template>
<script>
import YDialog from '@/components/dialog';
import RuleModal from './components/rule';
import PrizeModel from './components/address-prize-model';
import YLoading from '@/components/loading';
import {
  getBlindboxActivityInfoApi,
  getBlindboxOpenTimeApi,
  openMyBlindboxApi,
  inviteHelpBlindboxApi,
  getWinnerRotationDataApi,
} from '@/api/base1';
import { debounce, getNewsYMDHMS, changeParam } from '@/utils';
import { getAppStatus } from '@/utils/app';

let scrollLeft = 0;
let timer = null;
export default {
  name: 'BlindHome',
  components: {
    YDialog,
    RuleModal,
    PrizeModel,
    YLoading,
  },
  mounted() {
    let { scrollWidth, clientWidth } = this.$refs.prizeEle;
    this.distance = scrollWidth - clientWidth;
    this.loadDataAction();
    this.$nextTick((_) => {
      // this.prizeListMove();
      // this.prizeListScroll();
      this.wH = document.body.clientHeight;
      window._monitor('pageset', 'hdym_imp', {
        eventName: '活动页面曝光',
      });
    });
  },
  data() {
    return {
      visible: false,
      ruleVisible: false,
      dialogData: {
        title: '活动未开始',
        content: '当前活动未开始，请于{活动开始时间}来参与活动',
        visible: false,
      },
      animate: false,
      marqueeList: [],
      prizeList: [
      ],
      scrollLeft: 0,
      isOpen: false,
      openCount: 0,
      prizeVisible: false,
      prizeData: {},
      loadingVisible: false,
      copyText: '',
      wH: 0,
    };
  },
  computed: {
    cuserId() {
      return getAppStatus().cuserId;
      // return 'd6d2544a-52df-4192-8003-2d60ee24cda3';
      // return '7fafe1a4-ec10-4a41-b33e-365845d41032';
    },
    toastStyle() {
      return {
        backgroundColor: '#18695c',
      };
    },
    activityId() {
      return 2;
    },
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    loading(val) {
      val && this.getPrizeList();
      val && this.getOpenTimes();
    },
    ruleVisible(val) {
      this.$parent.disabledScroll = val;
    },
    $route(val) {
      console.log(val, 'route');
    },
  },
  created() {
    // this.openSession();
    this.winnerRotationData();
  },
  methods: {
    loadDataAction() {
      this.getPrizeList();
      this.getOpenTimes();
    },
    async getPrizeList() {
      let res;
      this.loadingVisible = true;
      const { cuserId, activityId } = this;
      try {
        res = await getBlindboxActivityInfoApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.loadingVisible = false;
        return this.$toast(e?.desc || '网络异常');
      }
      this.loadingVisible = false;
      this.$emit('loading-action');
      if (res?.code === 1) {
        let { prizeList } = res?.results?.prizePool;
        this.prizeList = prizeList;
        let { inviteMessageSign } = res?.results || {};
        const { startTime, endTime } = res?.results || {};
        if (timer) {
          window.clearTimeout(timer);
          timer = null;
          this.distance = 0;
        }
        this.activityStatus(startTime, endTime, inviteMessageSign);
        // this.prizeStyle();
      }
    },
    prizeStyle() {
      const { prizeEle, prizeRow } = this.$refs;
      this.$nextTick((_) => {
        let prizeW = prizeEle.clientWidth;
        let prizeRowW = prizeRow.clientWidth;
        this.distance = prizeW - prizeRowW;
        this.prizeDistance = this.distance;
        this.scrollSpeed();
      });
    },
    activityStatus(startTime, endTime, inviteMessageSign) {
      let currentTime = new Date().getTime();
      // currentTime = 1672243300000;
      console.log(currentTime, startTime);
      if (currentTime < startTime) {
        this.dialogData = {
          title: '活动未开始',
          content: `当前活动未开始，请于${getNewsYMDHMS(startTime)}来参与活动`,
          visible: true,
          type: 'activityTime',
        };
        return false;
      }
      if (currentTime > endTime) {
        this.dialogData = {
          title: '活动已结束',
          content: '如有未兑换的奖品请尽快兑换',
          visible: true,
          type: 'endTime',
        };
        return false;
      }
      if (inviteMessageSign) {
        this.dialogData = {
          title: '免费拆盲盒',
          content: '恭喜你完成任务，获得1次拆盲盒机会',
          visible: true,
          type: 'close',
        };
      }
    },
    async getOpenTimes() {
      let res;
      const { cuserId, activityId } = this;
      try {
        res = await getBlindboxOpenTimeApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        this.openCount = res?.results || 0;
        // this.openCount = 0;
      }
    },
    okAction() {
      this.dialogData.visible = false;
      const { type } = this.dialogData;
      type === 'activityTime' && window?.android?.jzClose();
      type === 'activityTime' && window?.ios?.jzClose();
      if (type === 'wechart') {
        const clipboard = new window.Clipboard('.copyBtn');
        clipboard.on('success', (_) => {
          this.$toast({
            content: '复制成功',
            style: this.toastStyle,
          });
          window?.android?.jumpRoute('apps://com.tencent.mm');
          window?.ios?.jumpRoute('yyjz://app/jumpThirdParty?url=weixin://');
          clipboard?.destroy();
        });
        clipboard.on('error', (_) => {
          console.log('浏览器不支持复制');
          clipboard?.destroy();
        });
      }
    },
    showMarquee() {
      this.animate = true;
      window.setTimeout(() => {
        this.marqueeList.push(this.marqueeList[0]);
        this.marqueeList.shift();
        this.animate = false;
      }, 500);
    },
    scrollSpeed(n = 5) {
      if (this.distance > 0 && !this.isEnd) {
        this.distance -= n;
        // console.log(n);
        this.scrollLeft += n;
        timer = window.setTimeout(() => {
          this.scrollSpeed();
        }, 200);
      }
      let left = Math.abs(this.scrollLeft);
      // console.log(left);
      if ((this.distance <= 0 && !this.end) || (left > this.prizeDistance)) {
        this.scrollLeft = 0;
        window.clearTimeout(timer);
        timer = null;
        window.setTimeout(() => {
          this.prizeStyle();
        }, 1500);
      }
    },
    animateLoop(n = 5) {
      // this.scrollSpeed();
      this.scrollLeft += n;
      window.requestAnimationFrame(this.animateLoop);
    },
    jumpAction() {
      this.dialogData.visible = false;
      // this.$router.push('/blind/prize');
      window.setTimeout(() => {
        this.$router.push('/blind/prize');
      }, 300);
    },
    disBlindBoxAction: debounce(async function (data) {
      let res;
      const { cuserId, activityId } = this;
      this.isOpen = true;
      if (this.disabled) return false;
      this.disabled = true;
      window._monitor('track', 'cmhan_cj_click', {
        eventName: '拆盲盒按钮(抽奖)点击',
      });
      try {
        res = await openMyBlindboxApi({ cuserId, activityId });
      } catch (e) {
        console.log(e);
        this.disabled = false;
        return this.$toast({
          content: '拆盲盒失败，请稍后再试',
          style: this.toastStyle,
        });
      }
      if (res?.code === 1) {
        window.setTimeout(() => {
          this.isOpen = false;
        }, 401);
        this.getPrizeData(res?.results || '');
        this.getOpenTimes();
        // this.getPrizeData();
      }
    }, 600, true),
    getPrizeData(id) {
      const { prizeList } = this;
      let prizeData = prizeList.find((item) => item.id === id);
      this.prizeData = prizeData;
      window._monitor('pageset', 'cmhcgtc_imp', {
        eventName: '拆盲盒成功弹窗曝光',
      });
      if (prizeData) {
        this.prizeVisible = true;
      }
      // this.disabled = false;
    },
    closeDialog() {
      this.prizeVisible = false;
      this.disabled = false;
    },
    prizeOkAction() {
      this.prizeVisible = false;
      window.setTimeout(() => {
        this.$router.push('/blind/prize');
      }, 600);
    },
    paymentAction() {
      let { origin } = window.location;
      let payMoney = origin.includes('jz-h5.yofish.com') ? 5.88 : 0.01; // 5.88
      let params = {
        payMoney, // 5.88
        payType: 0, // 0 支付宝 1：零钱
        smsCode: '', // 验证码 payType=1必传
        jumpUrl: origin + '/blind/home', // 跳转链接 payType=0必传
        scenes: 6, // 支付场景 0：皮肤 1：购买会员 2:导出数据 3：数据恢复 4：打赏 6: 拆盲盒
        goodsId: `blindbox_activity_${this.activityId}`, // 购买的商品id,(皮肤，会员必传)
        orderId: '', // 订单id ：scenes=2和3必传(需要先调用对应的生成订单接口)
        memo: '', // 备注 ：scenes=4必传
        activityId: this.activityId,
      };
      console.log(params);
      window._monitor('track', 'cmhan_gm_click', {
        eventName: '拆盲盒按钮(购买)点击',
      });
      if (/i(os|phone|pad)/i.test(navigator.userAgent)) {
        this.$router.push({
          path: '/payment',
          query: params,
        });
      }
      params = JSON.stringify(params);
      return window?.android?.toAliPay(params);
    },
    inviteAction() {
      let { openCount } = this; // 拆盲盒购买次数
      // openCount = 2;
      let totalTask = 2; // 总任务完成数
      let completeTask = 0;
      // if (!!openCount && totalTask < 3 && !completeTask) {
      //   this.dialogData = {
      //     title: '邀请码复制成功',
      //     content: '打开【有鱼记账】app，复制邀请码【8812932364】，帮我助力抽盲盒',
      //     type: 'wechart',
      //     visible: true,
      //   };
      // }
      this.inviteApiAction();
    },
    async inviteApiAction() {
      let res;
      const { cuserId, activityId } = this;
      let data = {
        cuserId,
        activityId,
      };
      data = changeParam(data);
      try {
        res = await inviteHelpBlindboxApi({}, data);
      } catch (e) {
        if (e.code === -110001) {
          this.dialogData = {
            title: '邀请新用户失败',
            content: '抱歉，您未购买过拆盲盒。无法参与邀请新用户助力任务',
            visible: true,
            type: 'close',
          };
          return false;
        }
        return e.code !== 1 && this.$toast({ content: e?.desc || '网络异常' });
      }
      if (res?.code === 1) {
        let { results } = res;
        this.copyText = `打开"有鱼记账" app，复制邀请码【${activityId}&${results}】，帮我助力抽盲盒`;
        console.log(this.copyText);
        window._monitor('pageset', 'yqmfzcgtc_imp', {
          eventName: '邀请码复制成功弹窗曝光',
        });
        this.dialogData = {
          title: '邀请码复制成功',
          content: `打开"有鱼记账" app，复制邀请码【${activityId}&${results}】，帮我助力抽盲盒`,
          type: 'wechart',
          visible: true,
        };
      }
    },
    pageScroll() {
      let { scrollTop } = document.documentElement;
      console.log(scrollTop);
      if (scrollTop <= 0) {
        // this.loading = true;
        this.$emit('loading-action');
      }
    },
    async winnerRotationData() {
      let res;
      const { activityId } = this;
      try {
        res = await getWinnerRotationDataApi({ activityId });
      } catch (e) {
        console.log(e);
      }
      if (res?.code === 1) {
        let { results } = res;
        results = results.map((item) => ({ ...item, name: `尾号${item.suffixNum}刚刚抽取了${item.prizeName}` }));
        this.marqueeList = results;
        window.setInterval(this.showMarquee, 2000);
      }
    },
    prizeListMove() {
      const { prizeEle } = this.$refs;
      let startPosition = 0;
      let distance = 0;
      prizeEle.addEventListener('touchstart', (e) => {
        e.stopPropagation();
        startPosition = e.touches[0].pageX;
      });
      prizeEle.addEventListener('touchmove', (e) => {
        e.stopPropagation();
        let currentPosition = e.touches[0].pageX;
        distance = currentPosition - startPosition;
        console.log(distance, 'distance');
        console.log(this.scrollLeft);
        distance += 10;
        this.scrollLeft -= distance;
      });
      prizeEle.addEventListener('touchend', (e) => {
        e.stopPropagation();
        distance = 0;
        this.scrollLeft -= distance;
        let left = Math.abs(this.scrollLeft);
        console.log(left, this.prizeDistance);
        if (left > this.prizeDistance) {
          this.scrollLeft = this.prizeDistance;
          this.distance = 0;
        }
      });
    },
    prizeListScroll() {
      const { prizeRow, prizeEle } = this.$refs;
      console.log(prizeRow.scrollWidth);
      this.automaticScroll();
      prizeRow.addEventListener('scroll', (e) => {
        // console.log(prizeRow.scrollLeft, prizeEle.clientWidth);
        this.scrollLeft = prizeRow.scrollLeft;
        // $('#prizeRow').animate({ scrollLeft: this.scrollLeft + 'px' }, 500);
      });
    },
    automaticScroll(n = 5) {
      this.scrollLeft += 15;
      this.$nextTick((_) => {
        const { prizeRow, prizeEle } = this.$refs;
        let { scrollWidth } = prizeRow;
        let { clientWidth } = prizeEle;
        let { scrollLeft } = this;
        let timer = null;
        console.log(this.scrollLeft, scrollWidth, prizeRow.clientWidth);
        timer = window.setTimeout(() => {
          // console.log((scrollLeft + scrollWidth) > clientWidth);
          if ((scrollLeft + prizeRow.clientWidth) > scrollWidth) {
            this.scrollLeft = 0;
            $('#prizeRow').animate({ scrollLeft: 0 }, 500);
            this.prizeListScroll();
            window.clearTimeout(timer);
          } else {
            this.automaticScroll();
          }
        }, 400);
        $('#prizeRow').animate({ scrollLeft: scrollLeft + 'px' }, 500);
      });
    },
  },
  beforeDestroy() {
    this.isEnd = true;
    window.clearTimeout(timer);
    timer = null;
    this.dialogData.visible = false;
  },
};
</script>
<style scoped lang="scss">
.activity-dialog-main {
  width: 100%;
  z-index: 900 !important;
  /deep/ .y-dialog-main {
    width: 554px;
    height: auto;
  }
}
.activity-dialog {
  text-align: center;
  margin: 0 31px;
  .title {
    margin-top: 32px;
    font-size: 32px;
    font-weight: 500;
    color: #333333;
    line-height: 54px;
  }
  .content {
    margin-top: 24px;
    font-size: 28px;
    font-weight: 400;
    color: #666666;
    line-height: 54px;
  }
}
.dialog-btns {
  margin-top: 24px;
  padding: 31px 0;
  text-align: center;
  border-top: 1PX solid #EAE7E7;
  .btn {
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    color: #666666;
    &.primary {
      color: #00FFDA;
    }
    &.success {
      color: #00FFDA;
    }
  }
}
.home {
  position: relative;
  z-index: 20;
  /deep/ .y-dialog-main {
    width: 554px;
    height: auto;
  }
  .head {
    margin: 0 24px 0 0;
    text-align: right;
    > img {
      width: 127px;
    }
  }
  .home-bg {
    position: relative;
    z-index: 20;
    // padding-bottom: 80px;
  }
  .bg-image {
    width: 750px;
    height: 2086px;
    margin: auto;
    position: relative;
    background-image:
      url('//download-cos.yofish.com/blindbox-gongjushiyebu/20220728112733712-home-bg.png');
    background-size: cover;
    .bg-title {
      width: 635px;
      min-height: 278px;
      margin: auto;
      padding-top: 80px;
      position: relative;
      left: 12px;
      img {
        width: 100%;
      }
    }
  }
  .smile-icon {
    position: absolute;
    width: 140px;
    left: -15px;
    top: -35px;
    z-index: 10;
    img {
      width: 100%;
    }
  }
}
.marguee-row {
  width: 589px;
  height: 50px;
  background-image:
  url('//download-cos.yofish.com/blind-gongjushiyebu/20220728144523274-marquee-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin: 15px auto 0;
}
.marquee_box {
  display: block;
  position: relative;
  width: 589px;
  height: 30px;
  overflow: hidden;
  margin: auto;
  top: 10px;
}
.marquee_list {
  display: block;
  position: absolute;
  width: 100%;
}
.marquee_top {
	transition: all 0.5s;
	margin-top: -30px
}

.marquee_list li {
	height: 30px;
	line-height: 30px;
	font-size: 26px;
	// padding-left: 20px;
  // font-weight: 600;
  color: #ffffff;
  font-family: OPPOSans;
  text-align: center;
}

.marquee_list li span {
	padding: 0 2px;
}
.lamp-margin {
  padding-top: 58px;
}
.lamp-icon {
  width: 30px;
  img {
    width: 100%;
  }
}
.blind-box {
  position: relative;
  margin: 100px auto;
  transform-style: preserve-3d;
  transform-origin: center center 100px;
  width: 235px;
  height: 235px;
  // animation: rotating 2s linear infinite;
  animation: flipInY 2.8s linear infinite;
  // border: 8px solid red;
  // background: red;
  z-index: 10;
  > div {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    span {
      font-size: 30px;
      text-align: center;
      font-weight: 800;
    }
  }
  .bg1 {
    transform:translateZ(235px);
    transform-origin: top;
  }
  .bg2 {
    transform: rotateX(90deg);
    transform-origin: top;
  }
  .bg3 {
    transform: rotateY(90deg);
    transform-origin: right;
  }
  .bg4 {
    transform: rotateY(-90deg);
    transform-origin: left;
  }
  .bg5 {
      transform: rotateX(-90deg);
      transform-origin: bottom;
  }
  .bg6 {
      transform:rotateY(180deg);
  }
  img {
    width: 100%;
  }
}
.blind-txt-title {
  font-size: 26px;
  font-family: OPPOSans;
  font-weight: 400;
  color: #FFFFFF;
  text-align: center;
  padding-top: 35px;
  .green {
    color: #2CFFDD;
    font-weight: bold;
  }
}
.blind-prize {
  margin: 0 64px;
  position: relative;
  .center-line {
    width: 601px;
    margin: 72px auto 0;
    img {
      width: 100%;
    }
  }
  .prize-title-desc {
    font-size: 24px;
    font-family: OPPOSans;
    font-weight: bold;
    color: #d4f2ee;
    display: flex;
    align-items: center;
    margin-top: 20px;
    .line {
      width: 1PX;
      height: 20px;
      background: #d4f2ee;
      margin-left: 24px;
      margin-right: 24px;
    }
  }
  .prize-row {
    width: 100%;
    overflow: auto;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .prize-list {
    margin-top: 46px;
    display: flex;
    // width: 100%;
    overflow-x: hidden;
    float: left;
    // transition: transform .6s;
    // animation: all .3s;
    &::-webkit-scrollbar {
      display: none;
    }
    li {
      .prize-name {
        font-size: 22px;
        font-family: OPPOSans;
        font-weight: bold;
        color: #FFFFFF;
        margin-top: 23px;
      }
    }
  }
}
.prize-title {
  width: 551px;
  height: 50px;
  background-image:
  url('//download-cos.yofish.com/blind-gongjushiyebu/20220728160245936-title1.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin-top: 65px;
  font-size: 26px;
  font-family: OPPOSans;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 50px;
  > span {
    padding-left: 25px;
  }
}
.prize-title2 {
  width: 553px;
  height: 50px;
  background-size: cover;
  background-repeat: no-repeat;
  background-image:
  url('//download-cos.yofish.com/blind-gongjushiyebu/2022072816190492-title2.png');
}
.prize-frame {
  width: 170px;
  height: 170px;
  margin-right: 26px;
  background: #FFFFFF;
  border: 2px solid #00FFD6;
  border-radius: 6px;
  position: relative;
  .prize-image {
    width: 240px;
    text-align: center;
    margin-left: -35px;
    margin-top: -35px;
    img {
      width: 100%;
    }
  }
  .prize-desc {
    padding: 1px 11px;
    position: absolute;
    border-radius: 20px;
    background: rgba(51,51,51,.5);
    display: inline;
    font-size: 16px;
    font-family: OPPOSans;
    font-weight: 400;
    color: #FFFFFF;
    margin-left: 17px;
    bottom: 13px;
  }
  .sign {
    position: absolute;
    top: 10px;
    right: -25px;
    width: 100px;
    height: 26px;
    line-height: 26px;
    text-align: center;
    background: #22FFDB;
    clip-path: polygon(25% 0, 75% 0, 100% 100%, 0% 100%);
    transform: rotate(45deg);
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 800;
    color: #008b74;
  }
}
.blind-btn {
  width: 434px;
  height: 101px;
  background-image:
    url('./image/box-1.png');
  background-size: cover;
  margin: 48px auto 0;
  &:active {
    background-image:
    url('./image/box-11.png');
  }
  // animation: bounceScale 3.8s linear infinite;
  &.open-blind-btn {
    background-image:
    url('./image/open-btn.png');
    &:active {
      background-image:
    url('./image/open-btn1.png');
    }
  }
}
.prize-task {
  margin: 25px 0px 0 54px;
}
.prize-task-frame {
  width: 625px;
  height: 132px;
  background: #FFFFFF;
  border: 2px solid #00FFD6;
  border-radius: 6px;
  margin-top: 45px;
}
.task-title {
  font-size: 30px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #000000;
  padding-top: 12px;
  padding-left: 30px;
}
.task-content {
  display: flex;
  margin: 32px 35px 0 26px;
  align-items: center;
  .icon {
    width: 52px;
    margin-right: 27px;
    img {
      width: 100%;
    }
  }
  .desc-content {
    font-size: 26px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #333335;
    p.bold {
      font-size: 22px;
      margin-top: 10px;
    }
  }
  .button {
    width: 134px;
    height: 56px;
    background-image:
    url('//download-cos.yofish.com/blind-gongjushiyebu/2022072816461843-btn.png');
    background-size: cover;
    background-repeat: no-repeat;
    color: #FFFFFF;
    text-align: center;
    line-height: 56px;
    margin-left: auto;
    font-size: 25px;
    font-family: OPPOSans;
    font-weight: 500;
    color: #29FFDD;
    text-align: center;
    cursor: pointer;
  }
}
.task-desc {
  font-size: 20px;
  font-family: OPPOSans;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.76);
  padding: 37px 0 0 8px;
}
.upper {
  height: 15px;
  width: 716px;
  background-image:
    url('//download-cos.yofish.com/blind-gongjushiyebu/2022072814012596-lamp-upper.png');
  background-size: cover;
  background-repeat: no-repeat;
  margin: 16px auto 0;
  &.lamp-bottom {
    position: absolute;
    bottom: 18px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.blind-box-row {
  position: relative;
  z-index: 30;
  .blind-circle {
    position: absolute;
    width: 650px;
    height: 281px;
    // background-image:
    // url('//download-cos.yofish.com/blind-gongjushiyebu/20220728170828646-blind-circle.png');
    // background-size: cover;
    // background-repeat: no-repeat;
    z-index: 100;
    left: 50px;
    top: -20px;
    transform:translateZ(235px);
    transform-origin: top;
    img {
      width: 100%;
    }
  }
  .bubble {
    width: 162px;
    position: absolute;
    bottom: -190px;
    left: 65px;
    z-index: 500;
    animation: bounceDown 2.8s linear infinite;
    > img {
      width: 100%;
    }
    &:last-child {
      width: 135px;
      right: 80px;
      left: auto;
      bottom: -100px;
      animation: bounceUp 2.8s linear infinite;
    }
  }
}

@keyframes rotating {
  0% {
    transform: rotate3d(.6, .6, .6, 0deg);
    -webkit-transform: rotate3d(.6, .6, .6, 0deg);
    -moz-transform: rotate3d(.6, .6, .6, 0deg);
    -ms-transform: rotate3d(.6, .6, .6, 0deg);
    -o-transform: rotate3d(.6, .6, .6, 0deg);
  }

  to {
    transform: rotate3d(.6, .6, .6, 1turn);
    -webkit-transform: rotate3d(.6, .6, .6, 1turn);
    -moz-transform: rotate3d(.6, .6, .6, 1turn);
    -ms-transform: rotate3d(.6, .6, .6, 1turn);
    -o-transform: rotate3d(.6, .6, .6, 1turn);
  }
}
@keyframes flipInY {
    0% {
        -webkit-transform: rotate3d(.1, 0, 0,0deg);
        transform: rotate3d(.1, 0, 0,0deg);
        // -webkit-animation-timing-function: ease-in;
        // animation-timing-function: ease-in;
        // opacity: 0
        z-index: -30;
    }
    // 40% {
    //     -webkit-transform: perspective(400px) rotate3d(.6, .6, .6,180deg);
    //     transform: perspective(400px) rotate3d(.6, .6, .6,180deg);
    //     // -webkit-animation-timing-function: ease-in;
    //     // animation-timing-function: ease-in
    // }
    50% {
        -webkit-transform: rotate3d(.1, 0, 0,-180deg);
        transform: rotate3d(.1, .1, 0,-180deg);
        z-index: -30;
        // opacity: 1
    }
    // 80% {
    //     -webkit-transform: perspective(400px) rotate3d(.6, .6, .6,180deg);
    //     transform: perspective(400px) rotate3d(.6, .6, .6,180deg)
    // }
    100% {
        -webkit-transform:  rotate3d(.1, 0, 0, -360deg);
        transform: rotate3d(.1, 0, 0, -360deg);
        z-index: -30;
    }
}
@keyframes bounceDown {
  25% {
    transform: translateY(-24px);
  }
  50%,100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(24px);
  }
}
@keyframes bounceUp {
  25% {
    transform: translateY(24px);
  }
  50%,100% {
    transform: translateY(0);
  }
  75% {
    transform: translateY(-24px);
  }
}
@keyframes bounceScale {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.1);
  }
}
</style>
