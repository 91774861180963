<template>
  <div class="address-prize-dialog">
    <y-dialog :visible="visible"
      :appendToBody="true" class="address-prize-dialog">
      <div slot="header" class="header-title">
        <img
         v-if="dialogType === 'address'"
        src="//download-cos.yofish.com/blind-gongjushiyebu/20220729112230481-address-title.png"
        alt="">
        <img
         v-if="dialogType === 'prize'"
        src="//download-cos.yofish.com/blind-gongjushiyebu/20220729134133569-reward-title.png"
        alt="">
      </div>
      <div class="close-btn" @click="closeModel">
        <img src="//download-cos.yofish.com/ad-gongjushiyebu/20220615110726923-close-btn.png"
        alt="">
      </div>
      <div class="address-content" v-if="dialogType === 'address'">
        <div class="address-input">
          <label class="address-label">收件人：</label>
          <div class="user-input">
            <input type="text" v-model="userForm.receiptname" v-if="!isAddress"/>
            <div class="name" v-else>{{ userForm.receiptname }}</div>
          </div>
        </div>
        <div class="address-input">
          <label class="address-label">手机号：</label>
          <div class="user-input">
            <input type="text" v-model="userForm.call" v-if="!isAddress">
            <div class="name" v-else>{{ userForm.call }}</div>
          </div>
        </div>
        <div class="address-input" :class="{ noItems: isAddress }">
          <label class="address-label">收货地址：</label>
          <div class="user-input">
            <input type="text" v-model="userForm.address" v-if="!isAddress">
            <div class="name" v-else>{{ userForm.address }}</div>
          </div>
        </div>
        <p class="address-desc" v-if="!isAddress">注：收货地址确认后不可更改，请谨慎填写</p>
        <div class="btn" v-if="!isAddress" @click="submitAction">
          <img
          src="//download-cos.yofish.com/blind-gongjushiyebu/20220729111810737-sure-btn.png" alt="">
        </div>
      </div>
      <div class="prize-content" v-if="dialogType === 'prize'">
        <div class="prize-image">
          <img :src="prizeData.imageUrl" alt="">
        </div>
        <p class="prize-name">{{ prizeData.name }}</p>
        <p class="prize-desc" v-if="prizeData.desc">{{ prizeData.desc }}</p>
        <div class="btn" @click="okAction">
          <img src="//download-cos.yofish.com/blind-gongjushiyebu/20220729135117932-ok-btn.png"
          alt="">
        </div>
      </div>
    </y-dialog>
  </div>
</template>
<script>
import YDialog from '@/components/dialog';
import { getAddressBlindboxApi } from '@/api/base1';
import { isString } from '@/utils';

export default {
  name: 'AddressModel',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    YDialog,
  },
  data() {
    return {
      userForm: {
        receiptname: '',
        call: '',
        address: '',
      },
      isOpen: false,
    };
  },
  watch: {
    visible(val) {
      this.isOpen = val;
      console.log(val, this.isOpen);
      if (val && !this.isAddress) {
        Object.keys(this.userForm).forEach((key) => {
          this.userForm[key] = '';
        });
      }
    },
    isAddress(val) {
      if (val) {
        const { receiptname, call, address } = this.addressData;
        this.userForm = {
          receiptname,
          call,
          address,
        };
      }
    },
  },
  props: {
    dialogType: {
      type: String,
      default: 'address',
    },
    prizeData: {
      type: Object,
      default: () => {},
    },
    visible: {
      type: Boolean,
      default: false,
    },
    isAddress: {
      type: Boolean,
      default: true,
    },
    addressData: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    cuserId() {
      return '3129b4b2-a736-4e9b-97d8-7a8ee7f357fe';
    },
    toastStyle() {
      return {
        backgroundColor: '#18695c',
      };
    },
    activityId() {
      return 2;
    },
  },
  mounted() {
  },
  methods: {
    closeModel() {
      // this.$emit('update:visible', false);
      this.$emit('close');
    },
    okAction() {
      // this.closeModel();
      this.$emit('prize-ok');
    },
    submitAction() {
      let { address, call, receiptname } = this.userForm;
      const myreg = /^(((13[0-9]{1})|(14[0-9]{1})|(16[0-9]{1})|(17[0-9]{1})|(15[0-3]{1})|(15[4-9]{1})|(18[0-9]{1})|(199))+\d{8})$/;
      if (!receiptname) {
        return this.$toast({ content: '请填写收件人', style: this.toastStyle });
      }
      if (!call) {
        return this.$toast({ content: '请填写手机号', style: this.toastStyle });
      }
      if (call.length < 11 || !myreg.test(call)) {
        return this.$toast({ content: '手机号填写有误,请重新输入!', duration: 2000, style: this.toastStyle });
      }
      if (!address) {
        return this.$toast({ content: '请填写地址', style: this.toastStyle });
      }
      const data = this.userForm;
      let recordid = this.prizeData?.id || '';
      recordid = isString(recordid) ? recordid : recordid.toString();
      data.recordid = recordid;
      this.closeModel();
      this.$emit('submit', data);
    },
  },
};
</script>
<style lang="scss">
.address-prize-dialog {
  z-index: 200 !important;
 .y-dialog-main {
    height: auto;
    width: 554px;
    background: linear-gradient(360deg, #E7FFFC 0%, #FFFFFF 100%);
    border-radius: 24px;
    border-radius: 16px;
    .y-dialog {
      border: 2px solid rgba(#00FFDA, .27);
      margin: 10px;
      padding-bottom: 60px;
    }
  }
  .header-title {
    width: 470px;
    margin-left: auto;
    margin-right: auto;
    margin-top: -50px;
    position: relative;
    .title-text {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      font-size: 32px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #FFFFFF;
      line-height: 45px;
    }
    img {
      width: 100%;
    }
  }
  .address-content {
    margin: 30px 42px 0 42px;
  }
  .address-desc {
    font-size: 22px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    margin-top: 20px;
  }
  .btn {
    width: 310px;
    margin: 68px auto 0;
    font-weight: 600;
    color: #FFFFFF;
    img {
      width: 100%;
    }
  }
  .address-input {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    &.noItems {
      align-items: flex-start;
    }
    .address-label {
      width: 133px;
      font-size: 26px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #333333;
      line-height: 45px;
      flex: 0 0 auto;
      text-align: left;
    }
    .user-input {
      width: 293px;
      flex: 0 0 auto;
      font-size: 26px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 45px;
      input {
        border: none;
        width: 100%;
        margin-right: 4px;
        border: 2px solid #00FFDA;
        height: 67px;
        border-radius: 8px;
        font-size: 26px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 45px;
        padding-left: 24px;
      }
    }
  }
  .close-btn {
    position: absolute;
    width: 60px;
    top: -110px;
    right: -60px;
    > img {
      width: 100%;
    }
  }
  .prize-image {
    width: 250px;
    margin-top: -10px;
    margin-left: auto;
    margin-right: auto;
    img {
      width: 100%;
    }
  }
  .prize-name, .prize-desc {
    text-align: center;
  }
  .prize-name {
    font-size: 32px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    line-height: 45px;
    margin-top: -35px;
  }
  .prize-desc {
    font-size: 26px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 37px;
    margin-top: 10px;
  }
}
</style>
