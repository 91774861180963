<template>
  <div class="rule-modal" v-show="visible">
    <div class="y-model" @click="outsideClick"></div>
    <div class="model-body" :class="{ btt: visible }" ref="modelBody">
      <div class="header" ref="head">
        <div class="rule-title">活动规则</div>
        <div class="close-btn" @click="closeModal">
          <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAY
          AAABXAvmHAAAAAXNSR0IArs4c6QAAApBJREFUaEPtmFmKFEEQhr85gOAVfNEDe
          AEvIe77vo77vqMoiuI4o46KoigqegQf9ACewCv4oCC+S0CWBEl3Z0RlJM1A1Vt
          3Rf7xf5FZuc2wxJ+ZJe6fAWDaPTj0wNADlRWwDKE1Kce3ylye5muBL8DvUqMSw
          GrgexK5AVwtCQa8/wQIgDwlf8UAqf5XZeoWcCnA5DgJbf4nsAL4OylfkRC4Dlx
          RIneA8w0gtHmRXwfIfxMfC4AI3AQuKqV7wJmSuON9bn4j8MHS3gogWreBc0r0A
          XDSkqQQk5vfCry16noARPMucFqJPwJmrclGxOXmdwCvPXpeANG+D5xQSR4Dhz1
          JU2xufjfw0qvTB0ByzAFHVbJF4IAjeW5+H/Dc0f5/aF8AEVgADqmkL4C9BhO5+
          YPAU0O7kSE1ACIoifcr5VfArglmcvNHUiH6+i8uZBZh6fo9KvANsN3wwR5LQ9G
          SY2xMbQ90wvLx7VRZ3gFb1O+88qfSZFBlXhpHAYiWTH/blKOPwIa0mnZ7G3l9N
          k3H1eajAURPFqDNytlntTGTvy+kBTHEfAsA0ZQtwPoRDi+nLUmY+VYAopuP+Wt
          pUxhqvhVAbr4z3QQi8iMeV3kx3gwiEiCvfDfm5RTXDCIKIDefzzbNICIAcvPj5
          vkmELUA3hU2HKIGIDd/HHhomCdDIfoC5OblbDBvMN+FhEH0AcjNy5ngicN8KIQ
          XIOwklSiqe8IDEHKGHdFTskboGz/Xim0FqL49KAyx3hAWgKp7G8e30QuiBCCH9
          GfKxCbgvcOUNzSHWAX8mCRSAlgO/ErX3HL1Ubyr9DoufBMlf+Yj5TLgT4A5q8T
          KUuU7oSKhNeO04gaAaVV+GELTrvzQA0MPBFXgH/DLeTHq2VVZAAAAAElFTkSuQmCC" alt="">
        </div>
      </div>
      <div class="rule-content">
        <div class="course-page page">
	<p>
		<span style="font-size: 12pt;">一、</span>
		<span style="font-size: 12pt;">活动概况：</span>
	</p>
	<p>
		<span style="font-size: 12pt;">1、活动时间：</span>
		<span style="font-size: 12pt;">8</span>
		<span style="font-size: 12pt;">月</span>
		<span style="font-size: 12pt;">24</span>
		<span style="font-size: 12pt;">
			<font>日</font>
			<font>—</font>
		</span>
		<span style="font-size: 12pt;">9</span>
		<span style="font-size: 12pt;">月</span>
		<span style="font-size: 12pt;">22</span>
		<span style="font-size: 12pt;">日</span>
	</p>
	<p>
		<span style="font-size: 12pt;">2、活动内容：</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>1）活动期间，用户需先支付5.88元购买盲盒抽奖券，购买盲盒抽奖券的用户可获得一次盲盒抽取机会。其中盲盒入场券购买不限次数，每购买一次可获得一次抽取盲盒的机会。
		</span>
		<span style="font-size: 12pt;">抽奖券一经售出，不做退款</span>
		<span style="font-size: 12pt;">。</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>2）购买过盲盒抽奖券的用户，可通过完成任务获得额外的抽奖机会。
      邀请一个新用户下载并注册app即可额外获得1次抽奖机会。（一天只能完成一次，每个用户最多可完成3次）
      <span style="color: rgb(227, 55, 55);">注：若用户没有购买过入场券则不能做任务</span>
		</span>
		<span style="font-size: 12pt; color: rgb(227, 55, 55);">。</span>
	</p>
	<p>
		<span style="font-size: 12pt;">3、活动规则</span>
		<span style="font-size: 12pt;">：</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>1）活动期间，若
		</span>
		<span style="font-size: 12pt;">用户</span>
		<span style="font-size: 12pt;">获得抽取次数但未在</span>
		<span style="font-size: 12pt;">活动期间</span>
		<span style="font-size: 12pt;">内参与抽奖，则视作主动放弃该次抽奖资格，后续不可补抽；</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>2）用户在抽中奖品后超过有效期未兑换/使用的，视为用户主动放弃该奖品。
      确认领取各项权益前请仔细阅读相关协议和活动规则，用户领取权益视为对相关协议和活动规则的认可。中将奖品需要用户自动领取并使用。中奖记录可在活动页面中“
		</span>
		<span style="font-size: 12pt;">所有</span>
		<span style="font-size: 12pt;">
			<font>奖品</font>
			<font>”中查看。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 12pt;">4、奖品说明</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>1）现金奖品
		</span>
		<span style="font-size: 12pt;">：</span>
		<span style="font-size: 12pt;">用户中奖后，系统会在</span>
		<span style="font-size: 12pt;">24</span>
		<span style="font-size: 12pt;">小时</span>
		<span style="font-size: 12pt;">内将</span>
		<span style="font-size: 12pt;">中奖</span>
		<span style="font-size: 12pt;">现金发放至有鱼钱包当中，可自行查看提取</span>
		<span style="font-size: 12pt;">。</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>2）权益类奖品
		</span>
		<span style="font-size: 12pt;">：</span>
		<span style="font-size: 12pt;">用户中奖后，用户需手动领取，领取后</span>
		<span style="font-size: 12pt;">3</span>
		<span style="font-size: 12pt;">个工作日</span>
		<span style="font-size: 12pt;">
			<font>内到账。权益类产品须在活动期间内领取，超过有效期未兑换</font>/使用的，视为用户主动放弃该奖品
		</span>
		<span style="font-size: 12pt;">。</span>
		<span style="font-size: 12pt;">具体使用规则见权益领取页面详情。</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<b>肯德基套餐券、肯德基鸡翅：</b>
		</span>
	</p>
	<p>
		<span style="font-size: 12pt;">使用说明</span>
		<span style="font-size: 12pt;">：</span>
		<span style="font-size: 12pt;">a</span>
		<span style="font-size: 12pt;">.进入“肯德基”APP，点击“我的”-“我的卡包”-“到店自取”选择指定兑换券-点击“柜面核销”，显示出二维码</span>
		<span style="font-size: 12pt;">。</span>
		<span style="font-size: 12pt;">&nbsp;</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">b</span>
		<span style="font-size: 12pt;">.前往肯德基门店，出示二维码，扫码核销兑换指定产品。</span>
	</p>
	<p>
		<b>
			<span style="font-size: 12pt;">瑞幸</span>
			<span style="font-size: 12pt;">29</span>
			<span style="font-size: 12pt;">元</span>
			<span style="font-size: 12pt;">抵扣券</span>
			<span style="font-size: 12pt;">：</span>
		</b>
	</p>
	<p>
		<span style="font-size: 12pt;">兑换方法</span>
		<span style="font-size: 12pt;">：</span>
		<span style="font-size: 12pt;">&nbsp;</span>
		<span style="font-size: 12pt;">a</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">
			<font>登录瑞幸咖啡</font>luckin&nbsp;coffee&nbsp;APP或微信小程序，点击“我的”，选择“兑换优惠”—输入兑换码即可完成兑换
		</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">b</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">
			<font>下单时，在支付界面的</font>
			<font>“使用咖啡钱包”中选择相应的兑换码后，完成支付</font>
		</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">券码查询路径</span>
		<span style="font-size: 12pt;">：</span>
		<span style="font-size: 12pt;">
			<font>登录瑞幸咖啡</font>luckin&nbsp;coffee&nbsp;APP或微信小程序，点击“我的”，选择“咖啡钱包”，查看咖啡钱包中的券码
		</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">c.</span>
		<span style="font-size: 12pt;">使用说明</span>
		<span style="font-size: 12pt;">:</span>
		<span style="font-size: 12pt;">i</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">饮品券有不同的固定面值，分别对应不同档位商品价格，
    结算时饮品券可抵扣相应面值的商品费用，但不包含风味糖浆、奶油等辅料及配送费</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">ii</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">饮品券一次性使用，不设找零，超额需补差价，一次可使用多张饮品券</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">iii</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">本通用饮品券不与其他优惠券叠加使用</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">iv</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">瑞幸咖啡将不对饮品券消费金额开具发票，敬请谅解</span>
		<span style="font-size: 12pt;">
			<br>
		</span>
		<span style="font-size: 12pt;">v</span>
		<span style="font-size: 12pt;">.</span>
		<span style="font-size: 12pt;">本产品为虚拟权益产品，兑换成功后，不退不换</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>3）
		</span>
		<span style="font-size: 12pt;">会员优惠券</span>
		<span style="font-size: 12pt;">奖品</span>
		<span style="font-size: 12pt;">：</span>
		<span style="font-size: 12pt;">用户领取后需在</span>
		<span style="font-size: 12pt;">有效期</span>
		<span style="font-size: 12pt;">内</span>
		<span style="font-size: 12pt;">
			<font>使用，超过有效期未兑换</font>/使用的，视为用户主动放弃该奖品。
		</span>
		<span style="font-size: 12pt;">
			<font>会员优惠券可在</font>
			<font>“所有奖品”中查看。</font>
		</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>
			<font>4）</font>
		</span>
		<span style="font-size: 12pt;">7天会员</span>
		<span style="font-size: 12pt;">：用户中奖后，</span>
		<span style="font-size: 12pt;">会在</span>
		<span style="font-size: 12pt;">24</span>
		<span style="font-size: 12pt;">小时</span>
		<span style="font-size: 12pt;">内发放至用户参与抽奖的手机号码当中。</span>
	</p>
	<p>
		<span style="font-size: 12pt;">
			<font>（</font>
			<font>5）</font>
		</span>
		<span style="font-size: 12pt;">&nbsp;<font>实物奖品，用户中奖后，需要</font>
		</span>
		<span style="font-size: 12pt;">在活动期间</span>
		<span style="font-size: 12pt;">自主填写手机号码和</span>
		<span style="font-size: 12pt;">收货</span>
		<span style="font-size: 12pt;">地址，</span>
		<span style="font-size: 12pt;">
			<font>我们会在</font>
			<font>14个工作日内进行发放。因用户自身原因未填写领取信息或填写错误不全的领取信息，
        导致无法兑换或超过兑换时间以及中奖用户本人明确表示放弃领奖，本次中奖资格将作废。礼品以实物为准。</font>
		</span>
	</p>
	<p>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">本活动最终解释权归</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">有鱼记账</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">所有</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">，</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">如有疑问可添加微信：</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">youyujz</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">002</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">进行咨询</span>
		<span style="color: rgb(255, 0, 0); font-size: 12pt;">。</span>
	</p>
</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'RuleModal',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cH: 0,
      wH: 0,
    };
  },
  watch: {
    visible(val) {
      if (val) {
        document.body.style.overflow = 'hidden';
        document.body.appendChild(this.$el);
      } else {
        document.body.style.overflow = 'auto';
      }
    },
  },
  mounted() {
  },
  methods: {
    closeModal() {
      this.$parent.ruleVisible = false;
    },
    outsideClick() {
      this.closeModal();
    },
  },
};
</script>
<style scoped lang="scss">
.rule-modal {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 200;
}
.y-model {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .6);
  z-index: 20;
}
.model-body {
  width: 100%;
  // max-width: 750px;
  // min-height: 80%;
  height: 90%;
  // border: 2px solid red;
  position: absolute;
  left: 0;
  bottom: 0;
  background: #fff;
  border-radius: 22px 22px 0 0;
  z-index: 30;
  // overflow: auto;
}
@keyframes btt-drawer-in {
  0% {
      transform: translateY(100%)
  }

  to {
      transform: translate(0)
  }
}
.btt {
  animation: btt-drawer-in .3s 1ms;
}
.close-btn {
  width: 50px;
  position: absolute;
  top: 27px;
  right: 28px;
  img {
    width: 100%;
  }
}
.rule-title {
  font-size: 34px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  line-height: 95px;
  text-align: center;
}
.rule-content {
  margin: 80px 0px 0 26px;
  position: relative;
  overflow: auto;
  padding-right: 26px;
  height: calc(100% - 80px);
  // border: 1px solid red;
}
.rule-main-cons {
  // overflow: hidden;
  height: calc(100vh - 80px);
}
.rule-activity-modal {
  font-family: PingFangSC-Medium, PingFang SC;
  line-height: 48px;
  margin-bottom: 20px;
  &:last-child {
    // margin-bottom: 140px;
  }
  .resume {
    font-size: 28px;
    font-weight: 500;
  }
}
.header {
  position: absolute;
  width: 100%;
  height: 80px;
  left: 0;
  line-height: 80px;
}
.red {
  color: red;
}
</style>
